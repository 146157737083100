<template>
  <div>
    <v-app-bar
    dark
    elevation="3"
    height="70px"
    color="#434242"
    fixed>
       <v-icon large class="mr-2 ml-1">$kiraakLogo</v-icon>
      <v-toolbar-title class="logo" @click="$router.push('/')">KIRAAK</v-toolbar-title>
    </v-app-bar>
  </div>
</template>
<script>
export default {
  methods: {
  }
}
</script>
<style scoped>
.logo {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 46px;
  color: #F2BA55;
}
</style>
