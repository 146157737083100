<template>
  <div>
    <TopNavigation />
    <div class="pa-10">
      <h2 class="privacyTitle">Privacy Policy</h2>
      <div class="privacyMessage mt-10">
        <p>
          This privacy policy governs your use of the software application Kiraak(“Application”) that was created by Kiraak Foods Private Limited. The Application allows customers to place orders for Fruits & Vegetables.
        </p>

        <div>
          <p class="privacyInfoTitle my-2">What information does the Application obtain and how is it used?</p>
          <p>User Provided Information</p>
          <p>The Application obtains the information you provide when you place an order.</p>
          <p>When you place an order with us and use the Application, you generally provide (a) your name,  address and phone number; (b) transaction-related information, such as when you make purchases, respond to any offers, or download or use applications from us; (c) information you provide us when you contact us for help; (d) credit card information for purchase and use of the Application, and; (e) information you enter into our system when using the Application, such as contact information.</p>
          <p>We may also use the information you provided us to contact you from time to time to provide you with important information, required notices and marketing promotions.</p>
        </div>

        <div>
          <p class="privacyInfoTitle my-2">Automatically Collected Information</p>
          <p>In addition, the Application may collect certain information automatically, including, but not limited to, the type of mobile device you use, your mobile devices unique device ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browsers you use, and information about the way you use the Application.</p>
        </div>

        <div>
          <p class="privacyInfoTitle my-2">Does the Application collect precise real time location information of the device?</p>
          <p>This Application does not collect precise information about the location of your mobile device.</p>
        </div>

        <div>
          <p class="privacyInfoTitle my-2">Do third parties see and/or have access to information obtained by the Application?</p>
          <p>Only aggregated, anonymized data is periodically transmitted to external services to help us improve the Application and our service. We will share your information with third parties only in the ways that are described in this privacy statement.</p>
          <p>We may disclose User Provided and Automatically Collected Information:</p>
          <ul>
            <li>as required by law, such as to comply with a subpoena, or similar legal process;</li>
            <li>when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to a government request;</li>
            <li>with our trusted services providers who work on our behalf, do not have an independent use of the information we disclose to them, and have agreed to adhere to the rules set forth in this privacy statement.</li>
            <li>If Kiraak Foods Pvt. Ltd is involved in a merger, acquisition, or sale of all or a portion of its assets, you will be notified via email and/or a prominent notice on our Web site of any change in ownership or uses of this information, as well as any choices you may have regarding this information.</li>
          </ul>
        </div>

        <div>
          <p class="privacyInfoTitle my-2">What are my opt-out rights?</p>
          <p>You can request to opt-out via email, at support@kiraak.co.</p>
        </div>

        <div>
          <p class="privacyInfoTitle my-2">Data Retention Policy, Managing Your Information</p>
          <p>We will retain User Provided data for as long as you use the Application and for a reasonable time thereafter. We will retain Automatically Collected information for up to 24 months and thereafter may store it in aggregate. If you’d like us to delete User Provided Data that you have provided via the Application, please contact us at privacy@kiraak.co and we will respond in a reasonable time. Please note that some or all of the User Provided Data may be required in order for the Application to function properly.</p>
        </div>

        <div>
          <p class="privacyInfoTitle my-2">Children</p>
          <p>We do not use the Application to knowingly solicit data from or market to children under the age of 13. If a parent or guardian becomes aware that his or her child has provided us with information without their consent, he or she should contact us at privacy@kiraak.co. We will delete such information from our files within a reasonable time.</p>
        </div>

        <div>
          <p class="privacyInfoTitle my-2">Security</p>
          <p>We are concerned about safeguarding the confidentiality of your information. We provide physical, electronic, and procedural safeguards to protect information we process and maintain. For example, we limit access to this information to authorized employees and contractors who need to know that information in order to operate, develop or improve our Application. Please be aware that, although we endeavor to provide reasonable security for information we process and maintain, no security system can prevent all potential security breaches.</p>
        </div>

        <div>
          <p class="privacyInfoTitle my-2">Changes</p>
          <p>This Privacy Policy may be updated from time to time for any reason. We will notify you of any changes to our Privacy Policy by posting the new Privacy Policy here and informing you via email or text message. You are advised to consult this Privacy Policy regularly for any changes, as continued use is deemed approval of all changes.</p>
        </div>

        <div>
          <p class="privacyInfoTitle my-2">Your Consent</p>
          <p>By using the Application, you are consenting to our processing of your information as set forth in this Privacy Policy now and as amended by us. "Processing,” means using cookies on a computer/hand held device or using or touching information in any way, including, but not limited to, collecting, storing, deleting, using, combining and disclosing information, all of which activities will take place in India. If you reside outside India your information will be transferred, processed and stored there under India’s privacy standards.</p>
        </div>

        <div>
          <p class="privacyInfoTitle my-2">Contact us</p>
          <p>If you have any questions regarding privacy while using the Application, or have questions about our practices, please contact us via email at privacy@kiraak.co.</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TopNavigation from './TopNavigation.vue'
export default {
  name: 'PrivacyPolicy',
  components: {
    TopNavigation
  },
  data () {
    return {
    }
  },
  methods: {

  },
  mounted () {

  }
}
</script>
<style scoped>
.privacyTitle {
  color: #fdfaf1;
  font-size: 30px;
}
.privacyMessage {
  color: #fdfaf1;
  font-size: 16px;
}
.privacyInfoTitle{
  font-weight: 900;
}
</style>
